/*
 * @Author: fanzh fanzh@leadingcloud.xyz
 * @Date: 2022-09-25 15:25:33
 * @LastEditors: fanzh fanzh@leadingcloud.xyz
 * @LastEditTime: 2022-10-12 14:57:52
 * @FilePath: /ldc-fe-site-xiaoke/src/components/footerFc.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import './footerFc.scss'
const FooterFc = () => {
    return (
        <footer className="m-footer site-footer mt-3 pt-5 pb-2 bg" style={{ paddingTop: '0rem !important' }}>
            <div className="l-content container">
                <div className="text-center text-muted">
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div>
                        <h5 className="text-light">联系我们</h5>
                            <StaticImage src="../assets/images/wx.png" alt="" style={{ width: '8rem', marginBottom: '30px' }} />
                            {/* <p style={{ color: 'white', marginTop: '-22px', marginBottom: '12px' }}>微信客服</p> */}
                        </div>
                        <div>
                            <h5 className="text-light">服务</h5>
                            <ul className="nav flex-column nav-pills">
                                {/*<a className="nav-link text-secondary pl-0 hover-f" href="https://www.xiaokeguanjia.com/support/" target={'_blank'}>帮助中心</a>*/}
                                <a className="nav-link text-secondary pl-0 hover-f" href="https://www.xiaokeguanjia.com/support/" target={'_blank'}>帮助中心</a>
                            </ul>
                        </div>
                    </div>
                    <p className="mb-0">
                        晓客管家© 2022 版权所有
                        &nbsp;&nbsp;
                        <span className="ml-2"><a className="text-secondary hover-f" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022087659号-4</a></span>
                        &nbsp;&nbsp;
                        <span className="ml-2"><a className="text-secondary hover-f" href="mailto:xk2021_02@163.com" target="_blank">xk2021_02@163.com</a></span>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default FooterFc;