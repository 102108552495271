import React, { useEffect, useState } from "react";

import Seo from "../components/seo"

import './index.scss';

import '../assets/css/bootstrap/4.4.1/bootstrap.min.css';

import Layout from "../components/layout"

import bg2 from '../assets/images/bg-2.jpg';

import bg3 from '../assets/images/bg-3.jpg';
import openGPT from '../assets/images/open_gpt4.png';

import { StaticImage } from "gatsby-plugin-image";
import { auto } from "@popperjs/core";



// import '../assets/css/app.css'

const Index: React.FC = () => {



    const [showMask, setShowMask] = useState<boolean>(false);

    const [showAiPage, setShowAiPage] = useState<boolean>(false);;

    const [noneScanDiv, setNoneScanDiv] = useState<boolean>(false);

    useEffect(() => {

        const u = navigator.userAgent;

        const mobile = !!u.match(/AppleWebKit.*Mobile.*/); //是否为移动终端

        const iPhone = u.indexOf('iPhone') > -1; //是否为iPhone或者QQHD浏览器

        const iPad = u.indexOf('iPad') > -1;

        const weixin = u.indexOf('MicroMessenger') > -1;

        const qq = u.match(/\sQQ/i) == "qq";

        if (mobile || iPhone || iPad || weixin || qq) {

            setNoneScanDiv(true);

        }

    }, [])





    const currentSystem = () => {

        if (typeof window !== `undefined`) {

            const u = navigator.userAgent;

            const browser = {//移动终端浏览器版本信息 

                trident: u.indexOf('Trident') > -1, //IE内核

                presto: u.indexOf('Presto') > -1, //opera内核

                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核

                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核

                mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端

                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端

                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器

                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器

                iPad: u.indexOf('iPad') > -1, //是否iPad  

                webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部

                weixin: u.indexOf('MicroMessenger') > -1, //是否微信 

                qq: u.match(/\sQQ/i) == "qq",//是否QQ

            };

            if (browser.ios || browser.iPhone || browser.iPad) {

                alert('暂无IOS版本')

            } else if (browser.weixin || browser.qq) {

                setShowMask(true)

            } else {

                const a = document.createElement('a');

                // a.href = 'https://wepro.oss-cn-shenzhen.aliyuncs.com/android/xiaoke/1.21.9/xiaoke_v1.21.9_20220928_release.apk';

                // a.href = 'https://wepro.oss-cn-shenzhen.aliyuncs.com/android/xkbeta-android/1.26.0/xiaoke_v1.26.0_20240411_release.apk';

                a.href = 'https://wepro.oss-cn-shenzhen.aliyuncs.com/android/xkgj-android/1.26.1/xkgj_v1.26.1_20240516_release.apk';

                a.click();

            }

        } else {

            console.log(`window：${window}`)

        }



    }



    return (

        <Layout>
            <Seo title="晓客管家官网"></Seo>
            <div>
                <div id="carouselExampleCaptions" className="carousel slide carousel-fade"
                data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="3000">
                            <img src={bg2} className="d-block w-100" alt="..." />
                            <div className="carousel-caption">
                                <h1 className="m-hero-title bigger text-align-center">
                                    晓客管家
                                </h1>
                                <p className="m-hero-description bigger text-align-center">
                                    工作更高效，身心更自由
                                </p>
                                <div className="download" style={{ display: 'flex', justifyContent:"center" }}>
                                    <button style={{ border: '1px solid #fff', borderRadius: '22px' }} className="download_go android_download btn btn-outline-dark"
                                    onClick={()=>
                                        currentSystem()} >
                                        <a id="mobileAndroidLinkA" style={{ display: 'block', width: '100%', height:
                                        '100%', lineHeight: '2rem' }} className="d-sm-block">
                                            <i className="iconfont icon-mobile">
                                            </i>
                                            &nbsp;点击下载
                                        </a>
                                    </button>
                                    {!noneScanDiv &&
                                    <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button style={{ border: '1px solid #fff', borderRadius: '22px' }} className="download_go android_download btn btn-outline-dark"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <a id="mobileAndroidLinkA" style={{ display: 'block', width: '100%', height:
                                            '100%', lineHeight: '2rem' }} className="d-sm-block">
                                                <i className="iconfont icon-mobile">
                                                </i>
                                                &nbsp;扫码下载
                                            </a>
                                        </button>
                                        </>
                                        }
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={bg3} className="d-block w-100" alt="..." />
                            <div className="carousel-caption">
                                <h1 className="m-hero-title bigger text-align-center">
                                ChatGPT4.0 账号
                                </h1>
                                <p className="m-hero-description bigger text-align-center">
                                ChatGPT 一键代开或升级，省时省力
                                </p>
                                <div className="download" style={{ display: 'flex', justifyContent:
                                "center" }}>
                                    <button style={{ border: '1px solid #fff', borderRadius: '22px' }} className="download_go android_download btn btn-outline-dark"
                                    onClick={()=>
                                        setShowAiPage(!showAiPage)} >
                                        <a id="mobileAndroidLinkA" style={{ display: 'block', width: '100%', height:
                                        '100%', lineHeight: '2rem' }} className="d-sm-block">
                                            <i className="iconfont icon-mobile">
                                            </i>点击查看
                                            &nbsp;
                                        </a>
                                    </button>
                                    {!noneScanDiv &&
                                    <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button style={{ border: '1px solid #fff', borderRadius: '22px' }} className="download_go android_download btn btn-outline-dark"
                                        onClick={()=> currentSystem()}
                                        >
                                            <a id="mobileAndroidLinkA" style={{ display: 'block', width: '100%', height:
                                            '100%', lineHeight: '2rem' }} className="d-sm-block">
                                                <i className="iconfont icon-mobile">
                                                </i>
                                                &nbsp; 立即下载
                                            </a>
                                        </button>
                                        </>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target=".carousel"
                    data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                        </span>
                        <span className="visually-hidden">
                            Previous
                        </span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target=".carousel"
                    data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true">
                        </span>
                        <span className="visually-hidden">
                            Next
                        </span>
                    </button>
                </div>

                <div className="l-content container" style={{ display: showAiPage ?  '' : 'none'}}>
                    <h5 className="text-center mt-2 mb-3" style={{ color: '#aaa' }}>
                        AI账号代开
                    </h5>
                    <h3 className="section-title text-center mb-4">
                        我们提供ChatGPT、ChatGPT4、ChatGPT Plus、ChatGPT-4o 账号代注册及升级服务。人工客服，安全可靠
                    </h3>


                </div>

                <div className="container" style={{ display: showAiPage ?  '' : 'none'}}>
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-4">
                        您下载 <b>晓客管家</b>App后，点击 <b>"我的"</b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg> 
                        &nbsp;<b>"订阅中心"</b>，就可以看到<b>GPT Plus</b>订阅列表，可以按需订阅。
                        <br></br>
                        <br></br>
                        各正规Android手机应用市场均可下载<b>晓客管家</b>App。
                        <br></br>
                        <br></br>
                        ChatGPT可以极大程度帮助个人及团队提升效率，带来业绩增长。亦可用于科研及学习用途。
                        <br></br>
                        <br></br>                 
                        </div>
                    
                        <div className="col col-lg-4">
                        <img src={openGPT} className="rounded navigation-images"  alt="OpenGPT" />
                        </div>
                    </div>
                </div>

                <div className="l-content container" style={{ display: showAiPage ?  'none' : ''}}>
                <h5 className="text-center mt-2 mb-3" style={{ color: '#aaa' }}>晓客管家能做什么</h5>
                    <h3 className="section-title text-center mb-4">轻量高效的联系人管理 客户关系管理 安全放心</h3>
                    <div className="feature-list pt-5 pr-5 pb-3 pl-5 mb-3">
                        <div className="row">
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-send icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">一键群发</h5>
                                        <div className="feature-description">批量发送，完美解决每次群发一个个选择好友的问题，节省大量时间。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-hearts icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">亲密群发</h5>
                                        <div className="feature-description">自动加上好友昵称/尊称，图片、表情等统统OK，制作出属于好友的专属群发。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-user-add icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">群内加好友</h5>
                                        <div className="feature-description">自行设置验证信息，加人时间间隔，加人起始位置，真实模拟人工操作，实现快速爆粉。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-user-group icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">群发群消息</h5>
                                        <div className="feature-description">提供检测微信群聊的功能，营销活动只要复制粘贴，为您一次性完成一键群发至所有目标群聊</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-user-circle icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">秒换头像</h5>
                                        <div className="feature-description">为您提供一键自动更换微信头像的功能，还可以私人定制专属头像，方便快捷。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-card icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">多消息转发</h5>
                                        <div className="feature-description">支持图片、视频、文章链接、小程序、公众号等多种消息类型，可批量发送。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-grid icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">一键九宫格</h5>
                                        <div className="feature-description">一键自动将目标图片切成九宫格的功能，让您一键拥有高颜值的朋友圈。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-tags icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">群发视频号</h5>
                                        <div className="feature-description">一键自动群发视频号到所有微信好友或群聊。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-screen icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">秒拼长图</h5>
                                        <div className="feature-description">自动快速拼接多张图片文件的功能，一键生成一张高清长图。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-crop icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">图片裁剪</h5>
                                        <div className="feature-description">一键将图片裁剪成不同比例大小，满足各种场景的应用。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-tag icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">群发小程序</h5>
                                        <div className="feature-description">一键自动群发小程序到所有微信好友或群聊。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="media mb-5">
                                    <i className="h2 mr-3 iconfont icon-search icn-size"></i>
                                    <div className="media-body">
                                        <h5 className="mb-1">检测僵尸粉</h5>
                                        <div className="feature-description">智能自动检测僵尸粉，无打扰，高效快捷。</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mask" className="mask" style={showMask ? { display: "block" }
            : { display: "none" }}>
                <StaticImage src={
                "http://www.lidezhushou.com/content/images/2020/01/browser-tip.png"} alt={ ""} />
            </div>
            {/* Download app modal */}
            <div className="modal fade qrCode" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
            aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                移动端安卓版
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    <StaticImage src={ "../assets/images/download_app.jpg"} alt={ ""}>
                                    </StaticImage>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                关闭
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>



    )

}



export default Index;